import { Dispatch, FC, SetStateAction } from 'react';
import alert from '../../assets/images/alert.svg';
import sbp from '../../assets/images/sbp.png';
import card from '../../assets/images/card.svg';
import crypto from '../../assets/images/crypto.svg';
import Container from '../../components/atoms/Container/Container';
import Field from '../../components/atoms/Field/Field';
import Button from '../../components/atoms/Button/Button';
import { Transaction } from '../../services/types';
import Header from '../../components/organisms/Header/Header';
import SupportButton from '../../components/atoms/SupportButton/SupportButton';
import styles from './CancellationPage.module.scss';

interface CancellationPageProps {
  transaction: Transaction;
  timeLeft: string;
  merchantName?: string;
  merchantImage?: string;
  currency?: string;
  ticketId?: string;
  paymentMethod?: string;
  paymentMethodImage?: string;
  amount?: number;
  onConfirm: () => void;
  setRetry: Dispatch<SetStateAction<number>>;
}

const CancellationPage: FC<CancellationPageProps> = ({
  transaction,
  timeLeft,
  amount,
  onConfirm,
  paymentMethod,
  ticketId,
  setRetry,
  currency = '₽',
}) => {
  const retryHandler = () => {
    if (typeof setRetry === 'function') {
      setRetry((prev) => prev + 1);
    }
  };

  const getPaymentMethodInfo = (paymentMethod: string | undefined) => {
    switch (paymentMethod) {
      case 'MC/VISA/MIR P2P RUB':
        return { img: card, text: 'По номеру карты' };
      case 'SBP P2P RUB':
        return { img: sbp, text: 'Пополнение через СБП' };
      case 'TRANS SBP P2P RUB':
        return { img: sbp, text: 'Пополнение через СБП' };
      case 'CRYPTO':
        return { img: crypto, text: 'Криптовалюта' };
      case 'A2A RUB':
        return { img: card, text: 'По номеру счёта' };
      default:
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      <Header
        transaction={transaction}
        ticketId={ticketId || ''}
        timeLeft={timeLeft}
      />

      <div className={styles.supportWrapper}>
        {transaction.merchant.toLowerCase() === 'vocxod' && (
          <SupportButton userId={transaction.externalUserId} />
        )}
      </div>
      <Container className={styles.container}>
        <div className={styles.contentWrapper}>
          <div className={styles.headWrapper}>
            <img src={alert} alt='error' />
          </div>

          <div className={styles.fieldGroup}>
            <Field
              bold
              text={`${amount || 0}.00 ${currency.replace('RUB', '₽') || ''}`}
              border='#FF857E'
              center
              fontSize='20px'
            />
            <Field
              text={getPaymentMethodInfo(paymentMethod)?.text}
              image={getPaymentMethodInfo(paymentMethod)?.img}
              border='#FF857E'
              center
            />
            <p className={styles.errorText}>
              Не удалось обработать ваш платеж.
            </p>
            <Button variant='error' onClick={onConfirm} outlined>
              Отмена
            </Button>
            {transaction?.recreationPaymentMethods.length > 0 ? (
              <div className={styles.transBox}>
                <div className={styles.transText}>
                  <p>Попробуйте новый метод.</p>
                  <p>Моментальные пополнения!</p>
                </div>
                <Button
                  variant='primary'
                  onClick={() => {
                    if (transaction?.recreationPaymentMethods[0] && window)
                      window?.location?.replace(
                        transaction?.recreationPaymentMethods[0]
                      );
                  }}
                >
                  Попробовать ТРАНСГРАН
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CancellationPage;
