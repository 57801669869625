import React, { Dispatch, SetStateAction } from 'react';
import errorImage from '../../assets/images/error-ok.svg';
import Button from '../../components/atoms/Button/Button';
import styles from './Error.module.scss';
import SupportButton from '../../components/atoms/SupportButton/SupportButton';

interface Props {
  onOk?: () => void;
  onRetry?: () => void;
  transaction?: any;
  onCancel?: () => void;
  setRetry?: Dispatch<SetStateAction<number>>;
  retry?: number;
}

const Error: React.FC<Props> = ({
  onOk,
  onRetry,
  setRetry,
  onCancel,
  retry,
  transaction,
}) => {
  const retryHandler = () => {
    if (typeof setRetry === 'function') {
      setRetry((prev) => prev + 1);
    }
  };
  return (
    <>
      <section className={styles.wrapper}>
        <div style={{ marginBottom: '28px' }}>
          {transaction?.merchant?.toLowerCase() === 'vocxod' && (
            <SupportButton userId={transaction.externalUserId} />
          )}
        </div>
        <div className={styles.container}>
          <img src={errorImage} alt='server error' />
          {onRetry && (
            <p className={styles.text}>
              Транзакция недоступна, попробуйте еще раз
            </p>
          )}
          {onOk && <p className={styles.text}>Ошибка в загрузке страницы</p>}
          {onCancel && <p className={styles.text}>Не удалось найти реквизит</p>}
          {setRetry ? (
            <>
              <div className={styles.btnWrapper}>
                <Button onClick={retryHandler}>Повторить попытку</Button>
              </div>
              <div className={styles.btnWrapper}>
                <Button
                  variant='secondary'
                  onClick={() => {
                    if (transaction) {
                      window.location.replace(transaction.redirectUrl);
                    }
                  }}
                >
                  Отмена
                </Button>
              </div>
            </>
          ) : (
            transaction?.redirectUrl && (
              <div className={styles.btnWrapper}>
                <Button onClick={onOk}>Назад</Button>
              </div>
            )
          )}
          {transaction?.recreationPaymentMethods.length > 0 ? (
            <div className={styles.transBox}>
              <div className={styles.transText}>
                <p>Попробуйте новый метод.</p>
                <p>Моментальные пополнения!</p>
              </div>
              <Button
                variant='primary'
                onClick={() => {
                  if (transaction?.recreationPaymentMethods[0] && window)
                    window?.location?.replace(
                      transaction?.recreationPaymentMethods[0]
                    );
                }}
              >
                Попробовать ТРАНСГРАН
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>
    </>
  );
};

export default Error;
